import { isDealerMode } from "../constant";
import { http } from "../utils/http";

export const loginWithPasswordApi = async (payload: {
  email: string;
  password: string;
}) => {
  if (isDealerMode())
    return await http.post("/v1/auth/dealer/login/password", payload, false);
  return await http.post("/v1/auth/admin/login/password", payload, false);
};

export const logoutApi = async () => {
  return await http.post("/v1/auth/logout", null);
};
