import { Link, useLocation } from "react-router-dom";
import { selectApp } from "../redux/app.reducer";
import { selectAuth } from "../redux/authentication.reducer";
import { useAppSelector } from "../redux/hooks";
import Menu from "./menu";
import { isDealerMode } from "../constant";
const $ = require("jquery");

const Sidebar = () => {
  const { pathname } = useLocation();
  const { rules } = useAppSelector(selectAuth);
  const { badge } = useAppSelector(selectApp);

  const isRouteActive = (pathname: string, path: string): string => {
    if (pathname.split("/").length < 2) {
      return "";
    }
    return pathname.split("/")[1] === path.split("/")[0] ? "active" : "";
  };

  const handleClickNav = () => {
    $(".sidebar.sidebar-offcanvas.active").toggleClass("active");
  };

  return (
    <nav
      className="sidebar sidebar-offcanvas"
      id="sidebar"
      style={{ zIndex: 3 }}
    >
      <ul className="nav ">
        {Menu.map((e) => {
          if (!isDealerMode() && !rules[e.id]) {
            return null;
          }
          return (
            <li
              key={e.link}
              className={`nav-item ${isRouteActive(pathname, e.link)}`}
            >
              <Link
                className="nav-link"
                onClick={() => {
                  handleClickNav();
                }}
                to={e.link}
              >
                <i className={e.icon}></i>
                <span className="menu-title">
                  {e.title}
                  {e.badgeKey === "order" ? (
                    <span
                      className="badge badge-pill badge-danger ml-1"
                      hidden={badge[e.badgeKey].value === 0}
                    >
                      {badge[e.badgeKey].value}
                    </span>
                  ) : (
                    !!e.badgeKey &&
                    badge[e.badgeKey] > 0 && (
                      <span className="badge badge-pill badge-danger ml-1">
                        {badge[e.badgeKey]}
                      </span>
                    )
                  )}
                </span>
              </Link>
            </li>
          );
        }).filter((e) => !!e)}
      </ul>
    </nav>
  );
};

export default Sidebar;
