import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  filterDealerApi,
  getDealerProfileByIdApi,
  getDealerQuotaApi,
  transferDealerQuotaApi,
  updateDealerQuotaApi,
} from "../../api/user";
import Datatable, { DatatableElement } from "../../component/datatable";
import {
  IDealerProfile,
  IDealerQuota,
  UpdateQuotaRequest,
} from "../../interface/user";
import { useAppSelector } from "../../redux/hooks";
import Breadcrumb from "../../component/breadcrumb";
import moment from "moment";
import { useQuery } from "react-query";
import { Modal, ModalBody } from "reactstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import useAppLoader from "../../component/hooks/useAppLoader";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import { isDealerMode } from "../../constant";
import { getPeriodLotteryByIdApi } from "../../api/lottery";
import { getSetting } from "../../api/setting";

declare let $: any;
interface DealerQuotaContextType {
  id: string;
  data: IDealerQuota | null;
  setData?: React.Dispatch<React.SetStateAction<IDealerQuota | null>>;
  refetch?: Function;
  user: IDealerProfile | null;
}
const DealerQuotaContext = React.createContext<DealerQuotaContextType>({
  id: "",
  data: null,
  user: null,
});

const QuotaFormModal = () => {
  const { id, data, setData, refetch, user } = useContext(DealerQuotaContext);
  const { data: periodLotteryData } = useQuery({
    queryKey: ["getPeriodLottery", data?.periodLottery.id],
    queryFn: async () => {
      if (data?.periodLottery.id)
        return await getPeriodLotteryByIdApi(data?.periodLottery.id);
      return null;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const { data: dealerList } = useQuery({
    queryKey: ["dealerList"],
    queryFn: async () => {
      return (
        await filterDealerApi({
          dealerName: "",
          isOpen: [true, false],
          limit: 999999,
          page: 1,
          name: "",
          status: [1, 0],
        })
      ).data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const options = useMemo(() => {
    return dealerList?.results?.filter((e) => e.id !== user?.id) ?? [];
  }, [dealerList, user]);
  const quota = useMemo<number | null>(() => {
    if (periodLotteryData?.data)
      return periodLotteryData.data.totalLottery - periodLotteryData.data.quota;
    return null;
  }, [periodLotteryData]);
  const { register, handleSubmit } = useForm<UpdateQuotaRequest>({
    defaultValues: { change: 0, periodLotteryId: data?.periodLottery.id },
  });
  const { showLoader, hideLoader } = useAppLoader();
  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();

  const onSubmit: SubmitHandler<UpdateQuotaRequest> = async ({ change }) => {
    showLoader();
    try {
      await updateDealerQuotaApi(id, {
        change,
        periodLotteryId: data?.periodLottery.id!,
      });
      openSnackbar();
      refetch!();
      setData!(null);
    } catch (e) {
      openErrorSnackbar(e);
    }
    hideLoader();
  };

  return data ? (
    <Modal
      size="md"
      isOpen
      toggle={() => {
        setData!(null);
      }}
      centered={false}
    >
      <div className="modal-title modal-header">
        <h5 className="modal-title">{`งวดที่ ${
          data.periodLottery.period.day
        } ${moment.months(data.periodLottery.period.month - 1)} ${
          data.periodLottery.period.year + 543
        }${data.periodLottery.status === 1 ? " ใช้งาน" : ""}`}</h5>
        <button
          type="button"
          onClick={() => {
            setData!(null);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isDealerMode() ? (
            <div className="h4 mb-4">{`โค้วต้าคงเหลือในระบบ ${
              quota !== null
                ? quota.toLocaleString("en-US", { maximumFractionDigits: 0 })
                : "-"
            }`}</div>
          ) : null}
          <div className="h4 mb-2">{`โค้วต้าที่มี ${data.quota.toLocaleString(
            "en-US",
            { maximumFractionDigits: 0 }
          )}`}</div>
          {data.periodLottery.status === 1 && !isDealerMode() ? (
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="input-group col-xs-12">
                      <input
                        {...register("change", { valueAsNumber: true })}
                        required
                        type="number"
                        className="form-control "
                        placeholder="จำนวนโควต้า"
                      />
                      <span className="input-group-append">
                        <button
                          className="file-upload-browse btn btn-sm btn-success"
                          type="submit"
                        >
                          บันทึก
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <div className="table-responsive ">
              <table id="dataTable" className="table">
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>วันเวลา</th>
                    <th>จาก</th>
                    <th>ถึง</th>
                    <th className="text-right">จำนวน</th>
                  </tr>
                </thead>
                <tbody>
                  {data.logs
                    .filter((e) => e.orderId === "000000000000000000000000")
                    .map((e, i) => {
                      if (
                        e.orderId === "000000000000000000000000" &&
                        e.fromDealerNo === null &&
                        e.toDealerNo === null
                      ) {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(e.createdAt).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )}
                            </td>
                            <td>ระบบ</td>
                            <td>ฉัน</td>
                            <td className="text-right">
                              {e.change.toLocaleString("en-US", {
                                maximumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                        );
                      }
                      if (e.fromDealerNo === user?.dealerInfo.dealerNo) {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(e.createdAt).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )}
                            </td>
                            <td>ฉัน</td>
                            <td>
                              {options.find(
                                (dealer) =>
                                  dealer.dealerInfo.dealerNo === e.toDealerNo
                              )?.dealerNo ?? ""}
                            </td>
                            <td className="text-right">
                              {e.change.toLocaleString("en-US", {
                                maximumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                        );
                      }
                      if (e.toDealerNo === user?.dealerInfo?.dealerNo) {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(e.createdAt).format(
                                "YYYY/MM/DD HH:mm:ss"
                              )}
                            </td>
                            <td>
                              {options.find(
                                (dealer) =>
                                  dealer.dealerInfo.dealerNo === e.fromDealerNo
                              )?.dealerNo ?? ""}
                            </td>
                            <td>ฉัน</td>
                            <td className="text-right">
                              {e.change.toLocaleString("en-US", {
                                maximumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  ) : null;
};

interface TransferQuotaFromData extends UpdateQuotaRequest {
  dealerId: string;
}

const QuotaTransferFormModal = () => {
  const { data, setData, refetch, user } = useContext(DealerQuotaContext);
  const { data: periodLotteryData } = useQuery({
    queryKey: ["getPeriodLottery", data?.periodLottery.id],
    queryFn: async () => {
      if (data?.periodLottery.id)
        return await getPeriodLotteryByIdApi(data?.periodLottery.id);
      return null;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const quota = useMemo<number | null>(() => {
    if (periodLotteryData?.data)
      return periodLotteryData.data.totalLottery - periodLotteryData.data.quota;
    return null;
  }, [periodLotteryData]);
  const { register, handleSubmit, setValue } = useForm<TransferQuotaFromData>({
    defaultValues: { change: 1, periodLotteryId: data?.periodLottery.id },
  });

  const { showLoader, hideLoader } = useAppLoader();
  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();

  const { data: dealerList } = useQuery({
    queryKey: ["dealerList"],
    queryFn: async () => {
      return (
        await filterDealerApi({
          dealerName: "",
          isOpen: [true, false],
          limit: 999999,
          page: 1,
          name: "",
          status: [1, 0],
        })
      ).data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const options = useMemo(() => {
    return dealerList?.results?.filter((e) => e.id !== user?.id) ?? [];
  }, [dealerList, user]);

  const onSubmit: SubmitHandler<TransferQuotaFromData> = async ({
    change,
    dealerId,
  }) => {
    if (!window.confirm("กรุณายืนยันการทำรายการรายการ")) return;
    showLoader();
    try {
      await transferDealerQuotaApi(dealerId, {
        change,
        periodLotteryId: data?.periodLottery.id!,
      });
      openSnackbar();
      refetch!();
      setData!(null);
    } catch (e) {
      openErrorSnackbar(e);
    }
    hideLoader();
  };

  useEffect(() => {
    if (data) {
      $(document).ready(function () {
        $(".js-select2-basic-single").select2();
        $(".js-select2-basic-single").on("select2:select", (e) => {
          var data = e.params.data;
          if (data.id !== "") {
            setValue("dealerId", data.id);
          } else {
            setValue("dealerId", "");
          }
        });
      });
    }
  }, [data]);

  return data ? (
    <Modal
      size="md"
      isOpen
      toggle={() => {
        setData!(null);
      }}
      centered={false}
    >
      <div className="modal-title modal-header">
        <h5 className="modal-title">{`งวดที่ ${
          data.periodLottery.period.day
        } ${moment.months(data.periodLottery.period.month - 1)} ${
          data.periodLottery.period.year + 543
        }${data.periodLottery.status === 1 ? " ใช้งาน" : ""}`}</h5>
        <button
          type="button"
          onClick={() => {
            setData!(null);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isDealerMode() ? (
            <div className="h4 mb-4">{`โค้วต้าคงเหลือในระบบ ${
              quota !== null
                ? quota.toLocaleString("en-US", { maximumFractionDigits: 0 })
                : "-"
            }`}</div>
          ) : null}
          <div className="h4 mb-2">{`โค้วต้าที่มี ${data.quota.toLocaleString(
            "en-US",
            { maximumFractionDigits: 0 }
          )}`}</div>
          <div className="row mb-2 mt-3" data-select2-id="6">
            <div className="col-md-12" data-select2-id="5">
              <select
                className="js-select2-basic-single w-100 from-control"
                style={{
                  width: "100%",
                }}
                tabIndex={-1}
                aria-hidden="true"
                {...register("dealerId", { required: true })}
                required
              >
                <option value="">รหัสร้านค้า</option>
                {options?.map((dealer) => (
                  <option key={dealer.id} value={dealer.id}>
                    {dealer.dealerNo}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {data.periodLottery.status === 1 && isDealerMode() ? (
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="input-group col-xs-12">
                  <input
                    {...register("change", { valueAsNumber: true, min: 1 })}
                    required
                    type="number"
                    className="form-control "
                    placeholder="จำนวนโควต้า"
                    min={1}
                  />
                  <span className="input-group-append">
                    <button
                      className="file-upload-browse btn btn-sm btn-success"
                      type="submit"
                    >
                      โอน
                    </button>
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <div className="table-responsive ">
              <table id="dataTable" className="table">
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>วันเวลา</th>
                    <th>โอนให้</th>
                    <th className="text-right">จำนวน</th>
                  </tr>
                </thead>
                <tbody>
                  {data.logs
                    .filter(
                      (e) =>
                        e.orderId === "000000000000000000000000" &&
                        e.fromDealerNo === user?.dealerInfo.dealerNo
                    )
                    .map((e, i) => (
                      <tr key={e.id}>
                        <td>{i + 1}</td>
                        <td>
                          {moment(e.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                        </td>
                        <td>
                          {
                            options.find(
                              (dealer) =>
                                dealer.dealerInfo.dealerNo === e.toDealerNo
                            )?.dealerNo
                          }
                        </td>
                        <td className="text-right">
                          {e.change.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  ) : null;
};

const DealerQuota = () => {
  let { id: userId } = useParams();
  const { data: settingData } = useQuery("fetchSettings", async () => {
    return (await getSetting()).data;
  });
  const myProfile = useAppSelector((state) => state.auth.user);
  const id = useMemo(() => userId ?? myProfile.id, [userId, myProfile]);
  const { data: userData } = useQuery({
    queryKey: ["dealerProfile", id],
    queryFn: async () => {
      return await getDealerProfileByIdApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const user = useMemo(() => {
    return userData?.data || null;
  }, [userData]);
  const [data, setData] = useState<IDealerQuota | null>(null);
  const [mode, setMode] = useState<"detail" | "transfer">("detail");
  const table = useRef<DatatableElement>(null);
  const { data: dealerQuotaData, refetch } = useQuery({
    queryKey: ["dealerQuota", id],
    queryFn: async () => {
      return await getDealerQuotaApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return (
    <DealerQuotaContext.Provider
      value={{ id: id?.toString() ?? "", data, setData, refetch, user }}
    >
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <Breadcrumb
          title={"สมาชิก"}
          children={[
            { title: "หน้าแรก", link: "/" },
            { title: "สมาชิก", link: "/dealers" },
            { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
            { title: "ประวัติการใช้งาน" },
          ]}
        />
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {user && (
                <Datatable
                  ref={table}
                  data={dealerQuotaData?.data ?? []}
                  columns={[
                    {
                      title: "งวดที่",
                      width: "80px",
                      data: "periodLottery.periodDate",
                      render: (_1, _2, row: IDealerQuota) => {
                        return `${row.periodLottery.period.day} ${moment.months(
                          row.periodLottery.period.month - 1
                        )} ${row.periodLottery.period.year + 543}`;
                      },
                    },
                    {
                      title: "วันเวลาเปิดขาย",
                      width: 100,
                      render: (_1, _2, row: IDealerQuota) => {
                        return moment(row.periodLottery.openDatetime).format(
                          "DD/MM/yyyy HH:mm:ss"
                        );
                      },
                    },
                    {
                      title: "สถานะ",
                      width: 100,
                      data: "periodLottery.status",
                      render: (data: number) => {
                        switch (data) {
                          case 0:
                            return "<span className='badge badge-danger'>ระงับ</span>";
                          case 1:
                            return "<span className='badge badge-success'>ใช้งาน</span>";
                          default:
                            return "";
                        }
                      },
                    },
                    {
                      title: "โควต้าที่ได้รับ",
                      width: 200,
                      render: (_1, _2, row: IDealerQuota) => {
                        const result = row.logs.reduce((a, b) => {
                          if (
                            (b.orderId === "000000000000000000000000" &&
                              b.fromDealerNo === null &&
                              b.toDealerNo === null) ||
                            b.toDealerNo === user.dealerInfo?.dealerNo
                          )
                            return a + b.change;
                          return a;
                        }, 0);
                        return result.toString();
                      },
                    },
                    {
                      title: "โควต้าคงเหลือ",
                      width: 200,
                      data: "quota",
                    },
                  ]}
                  actions={[
                    {
                      button: {
                        callback(data: IDealerQuota) {
                          setMode("detail");
                          setData(data);
                        },
                        condition: (data) => {
                          return data.periodLottery.status === 1;
                        },
                        code: (
                          <button className="btn btn-outline-primary btn-sm mb-1">
                            {isDealerMode() ? "ดู" : "แก้ไข"}
                          </button>
                        ),
                      },
                    },
                    {
                      button: {
                        callback(data: IDealerQuota) {
                          setMode("transfer");
                          setData(data);
                        },
                        condition: (data) => {
                          return (
                            data.periodLottery.status === 1 &&
                            isDealerMode() &&
                            settingData?.allowQuotaTransfer === true
                          );
                        },
                        code: (
                          <button className="btn btn-outline-primary btn-sm mb-1">
                            โอนโควต้า
                          </button>
                        ),
                      },
                    },
                    {
                      button: {
                        callback(data: IDealerQuota) {
                          setMode("detail");
                          setData(data);
                        },
                        condition: (data: IDealerQuota) => {
                          return data.periodLottery.status === 0;
                        },
                        code: (
                          <button className="btn btn-outline-primary btn-sm mb-1">
                            ดู
                          </button>
                        ),
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === "detail" ? <QuotaFormModal /> : <QuotaTransferFormModal />}
    </DealerQuotaContext.Provider>
  );
};

export default DealerQuota;
