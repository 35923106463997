import React from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";

type Props = {};

const useAppLoader = () => {
  const dispatch = useAppDispatch();
  const showLoader = () => {
    dispatch(setAppLoading(true));
  };
  const hideLoader = () => {
    dispatch(setAppLoading(false));
  };
  return {
    showLoader,
    hideLoader,
  };
};

export default useAppLoader;
