import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import debouce from "lodash/debounce";

const firebaseConfig = {
  apiKey: "AIzaSyCeGg4ZcuF9T6rharXVXiyFMTrOo8jS7FE",
  authDomain: "bootteryonline.firebaseapp.com",
  projectId: "bootteryonline",
  storageBucket: "bootteryonline.appspot.com",
  messagingSenderId: "984532162218",
  appId: "1:984532162218:web:e3ab674ac40ee2632422d3",
  measurementId: "G-41Q6YP01V7",
};

const msgKey =
  "BBBeLfnQsXv3z0fzLeuR8_ymaYu7D5BzDXeq5ii-kwwW5bNNY7O41EtF_eXPw7j1E5n1dMLkkapvAlZhWtvykGE";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (
  cb: (data: MessagePayload) => void
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("notification", payload);
      cb(payload);
      setTimeout(() => {
        debouncedPlaySound();
      }, 0);
      resolve(payload);
    });
  });

function playSound() {
  var audio = new Audio("/audio/notification.mp3");
  audio.play();
}

// Usage:
// This will create a debounced version of the playSound function
// that, when called repeatedly, will only actually invoke playSound
// at most once every 1 second.
var debouncedPlaySound = debouce(() => {
  playSound();
}, 1000);
