import { AxiosResponse } from "axios";
import { ISetting } from "../interface/setting";
import { http } from "../utils/http";

export const updateSetting = async (payload: {
  conversionRate: number;
  commissionPercentage: number;
}) => {
  return await http.post("/v1/admin/settings", payload, true);
};

export const updateSettingRecommended = async (payload: { recommended }) => {
  return await http.post("/v1/admin/settings/recommended", payload, true);
};

export const updateSettingQuotaTransfer = async (payload: {
  allowQuotaTransfer;
}) => {
  return await http.post("/v1/admin/settings/quota-transfer", payload, true);
};

export const getSetting = async (): Promise<AxiosResponse<ISetting | null>> => {
  return await http.get("/v1/settings", false);
};
