import React from "react";
import useSnackbar from "../../utils/snackbar";

type Props = {};

const useAppSnackbar = (
  position:
    | "bottom-left"
    | "bottom-right"
    | "top-left"
    | "top-right" = "bottom-left"
) => {
  const [open] = useSnackbar({ position });

  const openSnackbar = (msg?: string) => {
    open(msg || "ทำรายการสำเร็จ");
  };
  const openErrorSnackbar = (e: any) => {
    let msg;
    if (typeof e?.response?.data?.message === "string") {
      msg = e?.response?.data?.message;
    }
    openSnackbar(msg || "Error: Something went wrong.");
  };
  return {
    openSnackbar,
    openErrorSnackbar,
  };
};

export default useAppSnackbar;
