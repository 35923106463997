import React, { FC, useEffect } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { CreateDealerRequest } from "../../interface/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useRoutes } from "react-router-dom";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import { createDealerApi } from "../../api/user";
import useAppLoader from "../../component/hooks/useAppLoader";
const $ = require("jquery");
$.dropify = require("dropify");

type AddDealerFormData = {
  confirmPassword: string;
} & CreateDealerRequest;

const DealerAdd: FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm<AddDealerFormData>({
    mode: "onSubmit",
    defaultValues: { dealerNo: "BS" },
  });

  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();
  useEffect(() => {
    $(".dropify").dropify();
  }, []);

  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputs = document.querySelectorAll(
      "input[name='password'], input[name='confirmPassword']"
    );
    inputs?.forEach((input) =>
      input.setAttribute("type", e.target.checked ? "text" : "password")
    );
  };

  const onSubmit: SubmitHandler<AddDealerFormData> = async (values) => {
    showLoader();
    try {
      await createDealerApi({ ...values, status: values.status ? 1 : 0 });
      openSnackbar("เพิ่มตัวแทนจำหน่ายสำเร็จ");
      setTimeout(() => {
        hideLoader();
        navigate(-1);
      }, 1000);
    } catch (e) {
      hideLoader();
      openErrorSnackbar(e);
    }
  };

  console.log("watch", watch());

  return (
    <>
      <Breadcrumb
        title={"ตัวแทนจำหน่าย"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/dealers" },
        ]}
      />

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="picture" className="col-sm-2 col-form-label">
                    รูป
                  </label>
                  <div className="col-sm-9">
                    <input {...register("picture")} hidden />
                    <input
                      type="file"
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="400"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setValue("picture", reader.result as string);
                        };
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-2 col-form-label"
                  >
                    ชื่อ&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("firstName", { required: true })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="ชื่อ"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="lastName" className="col-sm-2 col-form-label">
                    นามสกุล
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("lastName")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="นามสกุล"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="dealerNo" className="col-sm-2 col-form-label">
                    รหัสตัวแทนจำหน่าย&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("dealerNo", { required: true })}
                      onInput={(e) => {
                        if (!e.currentTarget.value.startsWith("BS")) {
                          e.currentTarget.value = `BS`;
                        }
                      }}
                      type="text"
                      minLength={4}
                      required
                      className="form-control ml-auto"
                      placeholder="รหัสตัวแทนจำหน่าย"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="idCardNo" className="col-sm-2 col-form-label">
                    รหัสบัตรประชาชน
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("idCardNo")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="รหัสบัตรประชาชน"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="address" className="col-sm-2 col-form-label">
                    ที่อยู่
                  </label>
                  <div className="col-sm-5">
                    <textarea
                      {...register("address")}
                      className="form-control ml-auto"
                      style={{
                        maxWidth: "100%",
                        minHeight: 150,
                      }}
                      placeholder="ที่อยู่"
                      maxLength={5}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="phoneNumber"
                    className="col-sm-2 col-form-label"
                  >
                    เบอร์โทรศัพท์
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("phoneNumber")}
                      type="tel"
                      className="form-control ml-auto"
                      placeholder="เบอร์โทรศัพท์"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Facebook
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("facebook")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Facebook"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Line ID
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("line")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Line ID"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-2 col-form-label">
                    Email&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      className="form-control ml-auto"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="password">
                      Password&nbsp;
                      <span className="text-danger" style={{ fontSize: 12 }}>
                        *
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-5">
                    {errors.password?.type === "deps" && (
                      <span className="text-danger mb-1">
                        รหัสผ่านไม่ตรงกัน
                      </span>
                    )}
                    <input
                      {...register("password", {
                        required: true,
                      })}
                      className="form-control"
                      autoComplete="off"
                      type="password"
                      onInput={(e) => {
                        const value = e.currentTarget.value;
                        const confirmPassword = getValues("confirmPassword");
                        console.log(value, confirmPassword, getValues());
                        if (confirmPassword === value)
                          return clearErrors("password");
                        return setError("password", { type: "deps" });
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="confirmPassword">
                      Confirm password&nbsp;
                      <span className="text-danger" style={{ fontSize: 12 }}>
                        *
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      {...register("confirmPassword", {
                        required: true,
                      })}
                      className="form-control"
                      autoComplete="off"
                      type="password"
                      onInput={(e) => {
                        const value = e.currentTarget.value;
                        const password = getValues("password");
                        console.log(value, password);

                        if (password === value) return clearErrors("password");
                        return setError("password", { type: "deps" });
                      }}
                      required
                    />
                    <div className="form-check mt-4 ml-2">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => handleShowPassword(e)}
                        />
                        Show Password
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="status" className="col-sm-2 col-form-label">
                    สถานะ
                  </label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input
                        {...register("status")}
                        type="checkbox"
                        defaultChecked={true}
                      />
                      <span className="toggle-slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <button
                  type="button"
                  className="btn btn-light ml-auto mr-2"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dealers`);
                  }}
                >
                  ยกเลิก
                </button>
                <button type="submit" className="btn btn-primary mr-2">
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerAdd;
