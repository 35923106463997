import { FC, useEffect, useMemo } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { UpdateDealerRequest } from "../../interface/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import {
  getDealerProfileByIdApi,
  updateDealerProfileApi,
} from "../../api/user";
import useAppLoader from "../../component/hooks/useAppLoader";
import { useQuery } from "react-query";
import { getSrcImage } from "../../utils/utils";
import { useAppSelector } from "../../redux/hooks";
import { isDealerMode } from "../../constant";
const $ = require("jquery");
$.dropify = require("dropify");

type UpdateDealerProfileFormData = {} & UpdateDealerRequest;

const DealerEditProfile: FC = () => {
  let { id: userId } = useParams();
  const myProfile = useAppSelector((state) => state.auth.user);
  const id = useMemo(() => userId ?? myProfile.id, [userId, myProfile]);
  const { data, refetch } = useQuery({
    queryKey: ["dealerProfile", id],
    queryFn: async () => {
      return await getDealerProfileByIdApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const user = useMemo(() => {
    return data?.data || null;
  }, [data]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm<UpdateDealerProfileFormData>({ mode: "onSubmit" });

  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();

  useEffect(() => {
    if (user) {
      setValue("address", user.address);
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      setValue("dealerNo", user.dealerNo);
      setValue("status", user.status);
      setValue("email", user.email);
      setValue("facebook", user.facebook);
      setValue("line", user.line);
      setValue("idCardNo", user.idCardNo);
      setValue("phoneNumber", user.phoneNumber);
      setValue("picture", user.picture);

      const drEvent = $(".dropify").dropify({
        defaultFile: getSrcImage(user.picture) ?? "",
      });

      drEvent.on("dropify.afterClear", function (event, element) {
        setValue("picture", "");
      });
    }
  }, [user, setValue]);

  const onSubmit: SubmitHandler<UpdateDealerProfileFormData> = async (
    values
  ) => {
    showLoader();
    try {
      await updateDealerProfileApi(id!, {
        ...values,
        status: values.status ? 1 : 0,
      });
      openSnackbar();
    } catch (e) {
      openErrorSnackbar(e);
    }
    await refetch();
    hideLoader();
  };

  return (
    <>
      <Breadcrumb
        title={"ตัวแทนจำหน่าย"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/dealers" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "ประวัติส่วนตัว" },
        ]}
      />

      {!isDealerMode() ? (
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  <span className="nav-link active">ประวัติส่วนตัว</span>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/info/${id}`}>
                    ร้านค้า
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/book-bank/${id}`}>
                    บัญชีธนาคาร
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/history/${id}`}>
                    ประวิติคำสั่งซื้อ
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/password/${id}`}>
                    ตั้งค่ารหัสผ่าน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="picture" className="col-sm-2 col-form-label">
                    รูป
                  </label>
                  <div className="col-sm-9">
                    <input {...register("picture")} hidden />
                    <input
                      type="file"
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="400"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setValue("picture", reader.result as string);
                        };
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-2 col-form-label"
                  >
                    รหัสสมาชิก
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder="รหัสตัวแทน"
                      required
                      readOnly
                      disabled
                      value={user?.userNo}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-2 col-form-label"
                  >
                    รหัสตัวแทนจำหน่าย
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("dealerNo", { required: true })}
                      onInput={(e) => {
                        if (!e.currentTarget.value.startsWith("BS")) {
                          e.currentTarget.value = `BS`;
                        }
                      }}
                      disabled={isDealerMode()}
                      readOnly={isDealerMode()}
                      type="text"
                      minLength={4}
                      className="form-control ml-auto"
                      placeholder="รหัสตัวแทนจำหน่าย"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="firstName"
                    className="col-sm-2 col-form-label"
                  >
                    ชื่อ&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("firstName", { required: true })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="ชื่อ"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="lastName" className="col-sm-2 col-form-label">
                    นามสกุล
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("lastName")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="นามสกุล"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="idCardNo" className="col-sm-2 col-form-label">
                    รหัสบัตรประชาชน
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("idCardNo")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="รหัสบัตรประชาชน"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="address" className="col-sm-2 col-form-label">
                    ที่อยู่
                  </label>
                  <div className="col-sm-5">
                    <textarea
                      {...register("address")}
                      className="form-control ml-auto"
                      style={{
                        maxWidth: "100%",
                        minHeight: 150,
                      }}
                      placeholder="ที่อยู่"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="phoneNumber"
                    className="col-sm-2 col-form-label"
                  >
                    เบอร์โทรศัพท์
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("phoneNumber")}
                      type="tel"
                      className="form-control ml-auto"
                      placeholder="เบอร์โทรศัพท์"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Facebook
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("facebook")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Facebook"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Line ID
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("line")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Line ID"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-2 col-form-label">
                    Email&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      className="form-control ml-auto"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="status" className="col-sm-2 col-form-label">
                    สถานะ
                  </label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input
                        {...register("status")}
                        type="checkbox"
                        defaultChecked={true}
                      />
                      <span className="toggle-slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <button
                  type="button"
                  className="btn btn-light ml-auto mr-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  ยกเลิก
                </button>
                <button type="submit" className="btn btn-primary mr-2">
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerEditProfile;
