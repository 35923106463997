import { FC, useEffect, useMemo } from "react";
import Breadcrumb from "../../component/breadcrumb";
import {
  UpdateDealerBookbankRequest,
  UpdateDealerInfoRequest,
} from "../../interface/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import {
  getDealerProfileByIdApi,
  updateDealerBookbankApi,
  updateDealerInfoApi,
} from "../../api/user";
import useAppLoader from "../../component/hooks/useAppLoader";
import { useQuery } from "react-query";
import { getSrcImage } from "../../utils/utils";
import { getBanksApi } from "../../api/core";
import { useAppSelector } from "../../redux/hooks";
import { isDealerMode } from "../../constant";
const $ = require("jquery");
$.dropify = require("dropify");

type UpdateDealerBookbankFormData = {} & UpdateDealerBookbankRequest;

const DealerEditBank: FC = () => {
  let { id: userId } = useParams();
  const myProfile = useAppSelector((state) => state.auth.user);
  const id = useMemo(() => userId ?? myProfile.id, [userId, myProfile]);
  const { data, refetch } = useQuery({
    queryKey: ["dealerProfile", id],
    queryFn: async () => {
      return await getDealerProfileByIdApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const { data: bankData } = useQuery({
    queryKey: ["bank", id],
    queryFn: async () => {
      return await getBanksApi();
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const banks = useMemo(() => {
    return bankData?.data || [];
  }, [bankData]);

  const user = useMemo(() => {
    return data?.data || null;
  }, [data]);
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } =
    useForm<UpdateDealerBookbankFormData>({ mode: "onSubmit" });

  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();

  useEffect(() => {
    if (user && banks) {
      setValue("bankAccount.bankId", user.dealerInfo?.bankAccount.bankId);
      setValue(
        "bankAccount.branchName",
        user.dealerInfo?.bankAccount.branchName
      );
      setValue(
        "bankAccount.accountName",
        user.dealerInfo?.bankAccount.accountName
      );
      setValue(
        "bankAccount.accountNumber",
        user.dealerInfo?.bankAccount.accountNumber
      );
      setValue("qrCode", user.dealerInfo?.qrCode);

      const drop = $(".dropify").dropify({
        defaultFile: getSrcImage(user.dealerInfo?.qrCode) ?? "",
      });

      drop.on("dropify.afterClear", function () {
        setValue("qrCode", "");
      });
    }
  }, [user, setValue, banks]);

  const onSubmit: SubmitHandler<UpdateDealerBookbankFormData> = async (
    values
  ) => {
    showLoader();
    try {
      await updateDealerBookbankApi(id!, values);
      openSnackbar();
    } catch (e) {
      openErrorSnackbar(e);
    }
    await refetch();
    hideLoader();
  };

  return (
    <>
      <Breadcrumb
        title={"ตัวแทนจำหน่าย"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/dealers" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "บัญชีธนาคาร" },
        ]}
      />

      {!isDealerMode() ? (
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/profile/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/info/${id}`}>
                    ร้านค้า
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">บัญชีธนาคาร</span>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/history/${id}`}>
                    ประวิติคำสั่งซื้อ
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/password/${id}`}>
                    ตั้งค่ารหัสผ่าน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="qrCode" className="col-sm-2 col-form-label">
                    รูป QR Code
                  </label>
                  <div className="col-sm-9">
                    <input {...register("qrCode")} hidden />
                    <input
                      type="file"
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="200"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setValue("qrCode", reader.result as string);
                        };
                      }}
                      disabled={isDealerMode()}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor=""
                    className="col-sm-2 col-form-label required"
                  >
                    ธนาคาร
                  </label>
                  <div className="col-sm-9">
                    <select
                      {...register("bankAccount.bankId", { required: true })}
                      required
                      className="form-control"
                      disabled={isDealerMode()}
                    >
                      <option value={""}>เลือก</option>
                      {banks?.map((bank) => (
                        <option key={bank.id} value={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="bankAccount.accountNumber"
                    className="col-sm-2 col-form-label"
                  >
                    หมายเลขบัญชี&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("bankAccount.accountNumber", {
                        required: true,
                      })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="หมายเลขบัญชี"
                      required
                      disabled={isDealerMode()}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="bankAccount.accountName"
                    className="col-sm-2 col-form-label"
                  >
                    ชื่อบัญชี&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("bankAccount.accountName", {
                        required: true,
                      })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="ชื่อบัญชี"
                      required
                      disabled={isDealerMode()}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="bankAccount.branchName"
                    className="col-sm-2 col-form-label"
                  >
                    สาขา&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("bankAccount.branchName", {
                        required: true,
                      })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="สาขา"
                      required
                      disabled={isDealerMode()}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isDealerMode() ? null : (
              <div>
                <div className="row mt-3">
                  <button
                    type="button"
                    className="btn btn-light ml-auto mr-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button type="submit" className="btn btn-primary mr-2">
                    บันทึกข้อมูล
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerEditBank;
