import { FC, useEffect, useMemo } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { UpdateDealerInfoRequest } from "../../interface/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import { getDealerProfileByIdApi, updateDealerInfoApi } from "../../api/user";
import useAppLoader from "../../component/hooks/useAppLoader";
import { useQuery } from "react-query";
import { getSrcImage } from "../../utils/utils";
import { useAppSelector } from "../../redux/hooks";
import { isDealerMode } from "../../constant";
const $ = require("jquery");
$.dropify = require("dropify");

type UpdateDealerinfoFormData = {} & UpdateDealerInfoRequest;

const DealerEditInfo: FC = () => {
  let { id: userId } = useParams();
  const myProfile = useAppSelector((state) => state.auth.user);
  const id = useMemo(() => userId ?? myProfile.id, [userId, myProfile]);
  const { data, refetch } = useQuery({
    queryKey: ["dealerProfile", id],
    queryFn: async () => {
      return await getDealerProfileByIdApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const user = useMemo(() => {
    return data?.data || null;
  }, [data]);
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } =
    useForm<UpdateDealerinfoFormData>({ mode: "onSubmit" });

  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();

  useEffect(() => {
    if (user) {
      setValue("cover", user.dealerInfo?.cover);
      setValue("detail", user.dealerInfo?.detail);
      setValue("name", user.dealerInfo?.name);
      setValue("isOpen", user.dealerInfo?.isOpen);
      setValue("facebook", user.dealerInfo?.facebook);
      setValue("line", user.dealerInfo?.line);
      setValue("phoneNumber", user.dealerInfo?.phoneNumber);
      setValue("picture", user.dealerInfo?.picture);

      const dropCover = $("#dropCover").dropify({
        defaultFile: getSrcImage(user.dealerInfo?.cover) ?? "",
      });

      dropCover.on("dropify.afterClear", function () {
        setValue("picture", "");
      });

      const dropPicture = $("#dropPicture").dropify({
        defaultFile: getSrcImage(user.dealerInfo?.picture) ?? "",
      });

      dropPicture.on("dropify.afterClear", function () {
        setValue("picture", "");
      });
    }
  }, [user, setValue]);

  const onSubmit: SubmitHandler<UpdateDealerinfoFormData> = async (values) => {
    showLoader();
    try {
      await updateDealerInfoApi(id!, values);
      openSnackbar();
    } catch (e) {
      openErrorSnackbar(e);
    }
    await refetch();
    hideLoader();
  };

  return (
    <>
      <Breadcrumb
        title={"ตัวแทนจำหน่าย"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/dealers" },
          { title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}` },
          { title: "ร้านค้า" },
        ]}
      />

      {!isDealerMode() ? (
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/profile/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <span className="nav-link active">ร้านค้า</span>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/book-bank/${id}`}>
                    บัญชีธนาคาร
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/history/${id}`}>
                    ประวิติคำสั่งซื้อ
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/password/${id}`}>
                    ตั้งค่ารหัสผ่าน
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <label htmlFor="cover" className="col-sm-2 col-form-label">
                    รูป Cover (600x230)
                  </label>
                  <div className="col-sm-9">
                    <input {...register("cover")} hidden />
                    <input
                      id="dropCover"
                      type="file"
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="200"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setValue("cover", reader.result as string);
                        };
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="picture" className="col-sm-2 col-form-label">
                    รูป Profile ร้านค้า (400x400)
                  </label>
                  <div className="col-sm-9">
                    <input {...register("picture")} hidden />
                    <input
                      id="dropPicture"
                      type="file"
                      className="dropify"
                      data-max-file-size="3M"
                      data-height="200"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setValue("picture", reader.result as string);
                        };
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="name" className="col-sm-2 col-form-label">
                    ชื่อร้านค้า&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      *
                    </span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("name", { required: true })}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="ชื่อร้านค้า"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">
                    รหัสตัวแทนจำหน่าย
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder="รหัสตัวแทนจำหน่าย"
                      required
                      readOnly
                      disabled
                      value={user?.dealerNo}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">
                    ลิ้งร้านค้า
                  </label>
                  <div className="col-sm-5 d-flex">
                    <input
                      id="dealerNo"
                      type="text"
                      className="form-control ml-auto"
                      placeholder="รหัสตัวแทนจำหน่าย"
                      required
                      readOnly
                      disabled
                      value={`https://bosslotteryonline.com/dealer.html?id=${user?.dealerNo}`}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-primary col-sm-2 col-form-label ml-2"
                      style={{
                        height: 40,
                      }}
                      onClick={() => {
                        // Get the text field
                        var copyText = document.getElementById(
                          "dealerNo"
                        ) as HTMLInputElement;
                        if (copyText === null) return;
                        // Select the text field
                        copyText.select();
                        copyText.setSelectionRange(0, 99999); // For mobile devices

                        // Copy the text inside the text field
                        navigator.clipboard.writeText(copyText.value);

                        openSnackbar("คัดลอกลิงค์แล้ว");
                      }}
                    >
                      คัดลอก
                    </button>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="detail" className="col-sm-2 col-form-label">
                    รายละเอียด
                  </label>
                  <div className="col-sm-5">
                    <textarea
                      {...register("detail")}
                      className="form-control ml-auto"
                      style={{
                        maxWidth: "100%",
                        minHeight: 150,
                      }}
                      placeholder="รายละเอียด"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="phoneNumber"
                    className="col-sm-2 col-form-label"
                  >
                    เบอร์โทรศัพท์
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("phoneNumber")}
                      type="tel"
                      className="form-control ml-auto"
                      placeholder="เบอร์โทรศัพท์"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Facebook
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("facebook")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Facebook"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="facebook" className="col-sm-2 col-form-label">
                    Line ID
                  </label>
                  <div className="col-sm-5">
                    <input
                      {...register("line")}
                      type="text"
                      className="form-control ml-auto"
                      placeholder="Line ID"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="isOpen" className="col-sm-2 col-form-label">
                    สถานะ
                  </label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input
                        {...register("isOpen")}
                        type="checkbox"
                        defaultChecked={true}
                      />
                      <span className="toggle-slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <button
                  type="button"
                  className="btn btn-light ml-auto mr-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  ยกเลิก
                </button>
                <button type="submit" className="btn btn-primary mr-2">
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerEditInfo;
