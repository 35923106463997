import React, { FC, useEffect, useMemo } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { CreateDealerRequest } from "../../interface/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams, useRoutes } from "react-router-dom";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import {
  createDealerApi,
  getDealerProfileByIdApi,
  updatePasswordDealerApi,
} from "../../api/user";
import useAppLoader from "../../component/hooks/useAppLoader";
import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hooks";
import { isDealerMode } from "../../constant";
const $ = require("jquery");
$.dropify = require("dropify");

type DealerEditPasswordFormData = {
  password: string;
  confirmPassword: string;
};

const DealerEditPassword: FC = () => {
  let { id: userId } = useParams();
  const myProfile = useAppSelector((state) => state.auth.user);
  const id = useMemo(() => userId ?? myProfile.id, [userId, myProfile]);
  const { data, refetch } = useQuery({
    queryKey: ["dealerProfile", id],
    queryFn: async () => {
      return await getDealerProfileByIdApi(id!);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  const user = useMemo(() => {
    return data?.data || null;
  }, [data]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    getValues,
  } = useForm<DealerEditPasswordFormData>({ mode: "onSubmit" });

  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();
  useEffect(() => {
    $(".dropify").dropify();
  }, []);

  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputs = document.querySelectorAll(
      "input[name='password'], input[name='confirmPassword']"
    );
    inputs?.forEach((input) =>
      input.setAttribute("type", e.target.checked ? "text" : "password")
    );
  };

  const onSubmit: SubmitHandler<DealerEditPasswordFormData> = async ({
    password,
  }) => {
    showLoader();
    try {
      await updatePasswordDealerApi(id!, { password });
      openSnackbar();
    } catch (e) {
      openErrorSnackbar(e);
    }
    hideLoader();
  };

  console.log("watch", watch());

  return (
    <>
      <Breadcrumb
        title={"ตัวแทนจำหน่าย"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/dealers" },
        ]}
      />

      {!isDealerMode() ? (
        <div className="col-12 m-0">
          <div className="row">
            <div className="col">
              <ul
                className="nav nav-pills nav-fill"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/profile/${id}`}>
                    ประวัติส่วนตัว
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/info/${id}`}>
                    ร้านค้า
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/book-bank/${id}`}>
                    บัญชีธนาคาร
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link className="nav-link" to={`/dealers/history/${id}`}>
                    ประวิติคำสั่งซื้อ
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <span className="nav-link active">ตั้งค่ารหัสผ่าน</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="password">
                      Password&nbsp;
                      <span className="text-danger" style={{ fontSize: 12 }}>
                        *
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-5">
                    {errors.password?.type === "deps" && (
                      <span className="text-danger mb-1">
                        รหัสผ่านไม่ตรงกัน
                      </span>
                    )}
                    <input
                      {...register("password", {
                        required: true,
                      })}
                      className="form-control"
                      autoComplete="off"
                      type="password"
                      onInput={(e) => {
                        const value = e.currentTarget.value;
                        const confirmPassword = getValues("confirmPassword");
                        console.log(value, confirmPassword, getValues());
                        if (confirmPassword === value)
                          return clearErrors("password");
                        return setError("password", { type: "deps" });
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="confirmPassword">
                      Confirm password&nbsp;
                      <span className="text-danger" style={{ fontSize: 12 }}>
                        *
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      {...register("confirmPassword", {
                        required: true,
                      })}
                      className="form-control"
                      autoComplete="off"
                      type="password"
                      onInput={(e) => {
                        const value = e.currentTarget.value;
                        const password = getValues("password");
                        console.log(value, password);

                        if (password === value) return clearErrors("password");
                        return setError("password", { type: "deps" });
                      }}
                      required
                    />
                    <div className="form-check mt-4 ml-2">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => handleShowPassword(e)}
                        />
                        Show Password
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <button
                  type="button"
                  className="btn btn-light ml-auto mr-2"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dealers`);
                  }}
                >
                  ยกเลิก
                </button>
                <button type="submit" className="btn btn-primary mr-2">
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerEditPassword;
