import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  getSetting,
  updateSetting,
  updateSettingQuotaTransfer,
  updateSettingRecommended,
} from "../../api/setting";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoading } from "../../redux/app.reducer";
import useSnackbar from "../../utils/snackbar";
import { getLotteryStyle, updateLotteryStyle } from "../../api/lottery";
import { ILotteryStyle } from "../../interface/lottery";
import useAppLoader from "../../component/hooks/useAppLoader";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import { getSrcImage } from "../../utils/utils";

const jquery = require("jquery");
jquery.dropify = require("dropify");

const SettingLotteryHeadStyle = () => {
  const { data, isLoading, refetch } = useQuery(
    "fetchLotteryStyle",
    async () => {
      return (await getLotteryStyle()).data;
    }
  );
  const { register, handleSubmit, setValue, watch } = useForm<ILotteryStyle>();
  const { image, textColor } = watch();
  const { showLoader, hideLoader } = useAppLoader();
  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();

  const onSubmit: SubmitHandler<ILotteryStyle> = async (body) => {
    showLoader();
    try {
      await updateLotteryStyle(body);
      refetch();
      openSnackbar();
    } catch (e: any) {
      openErrorSnackbar(e);
    }
    hideLoader();
  };

  useEffect(() => {
    if (data !== undefined) {
      const dropBackground = jquery("#dropBackground").dropify({
        defaultFile:
          data != null && data.image.length > 0 ? getSrcImage(data.image) : "",
      });

      dropBackground.on("dropify.afterClear", function () {
        setValue("image", "");
      });

      setValue("image", data?.image ?? "");
      setValue("textColor", data?.textColor ?? "");
    }
  }, [data, setValue]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  ตัวอย่าง
                </label>
                <div className="col-sm-4">
                  <div className="position-relative">
                    <img
                      src="/images/lottery_example.png"
                      alt="example"
                      className="w-100 h-auto"
                    />
                    <div
                      className="position-absolute"
                      style={{
                        width: 72,
                        height: 72,
                        backgroundImage:
                          image != null && image.length > 0
                            ? `url(${getSrcImage(image)})`
                            : "none",
                        backgroundSize: "contain",
                        top: 0,
                        left: 0,
                        padding: 8,
                        color: textColor ?? "#000",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            lineHeight: 1,
                          }}
                        >
                          3
                        </span>
                        <span style={{ fontSize: 14, marginLeft: 4 }}>ใบ</span>
                      </div>
                      <div>
                        <span style={{ fontSize: 24, fontWeight: "bold" }}>
                          12
                        </span>
                        <span style={{ fontSize: 14, marginLeft: 4 }}>
                          ล้าน
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  สีตัวอักษร
                </label>
                <div className="col-sm-4">
                  <input
                    type="color"
                    className="form-control"
                    placeholder="สีตัวอักษร"
                    {...register("textColor")}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="dropBackground"
                  className="col-sm-3 col-form-label font-weight-bold"
                >
                  ภาพพื้นหลัง
                </label>
                <div className="col-sm-4">
                  <input
                    id="dropBackground"
                    type="file"
                    className="dropify"
                    data-max-file-size="5M"
                    data-allowed-file-extensions="jpg png jpeg"
                    onChange={(e) => {
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files![0]);
                      reader.onload = function () {
                        setValue("image", `${reader.result}`);
                      };
                    }}
                    style={{ minHeight: 100, height: 100 }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-4 text-right">
                  <button className="btn btn-primary" type="submit">
                    บันทึก
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingLotteryHeadStyle;
