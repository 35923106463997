import { AxiosResponse } from "axios";
import { http } from "../utils/http";
import {
  CreateDealerRequest,
  DealerListPagination,
  FilterDealerRequest,
  IAdminUpdateUser,
  ICreateAdmin,
  IDealerProfile,
  IDealerQuota,
  IUpdateAdmin,
  IUser,
  UpdateDealerBookbankRequest,
  UpdateDealerInfoRequest,
  UpdateDealerRequest,
  UpdateQuotaRequest,
  UserListPagination,
} from "../interface/user";

export const fetchProfileUserApi = async () => {
  return await http.get("/v1/users/profile");
};

export const filterUsersApi = async (payload: {
  role?: string;
  status?: string;
  name?: string;
  isNewMember?: boolean;
  isDealer?: string;
  page: number;
  limit: number;
}): Promise<AxiosResponse<UserListPagination>> => {
  return await http.get("/v1/admin/users/filter", payload);
};

export const filterDealerApi = async (
  payload: FilterDealerRequest
): Promise<AxiosResponse<DealerListPagination>> => {
  return await http.get("/v1/admin/dealers/filter", {
    ...payload,
    status: payload.status.toString(),
    isOpen: payload.isOpen.toString(),
  });
};

export const getDealerProfileByIdApi = async (
  id: string
): Promise<AxiosResponse<IDealerProfile>> => {
  return await http.get(`/v1/admin/dealers/${id}`);
};

export const getProfileUserByIdApi = async (
  id: string
): Promise<AxiosResponse<IUser>> => {
  return await http.get(`/v1/admin/users/${id}`);
};

export const updateUsersStatusApi = async (payload: {
  uid: string[];
  status: number;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/users/status`, payload);
};

export const deleteUsersApi = async (payload: {
  uid: string[];
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/users/delete`, payload);
};

export const updateUserProfileByIdApi = async (
  id: string,
  payload: IAdminUpdateUser
): Promise<AxiosResponse<void>> => {
  return await http.put(`/v1/admin/users/profile/${id}`, payload);
};

export const updateUserProfileByMeApi = async (
  payload: IAdminUpdateUser
): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/users/profile`, payload);
};

export const changePasswordByMeApi = async (payload: {
  oldPassword: string;
  newPassword: string;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/users/change-password`, payload);
};

export const createAdminApi = async (
  payload: ICreateAdmin
): Promise<AxiosResponse<IUser>> => {
  return await http.post(`/v1/admin/users/admin`, payload);
};

export const createDealerApi = async (
  payload: CreateDealerRequest
): Promise<AxiosResponse<IUser>> => {
  return await http.post(`/v1/admin/users/dealer`, payload);
};

export const getDealerQuotaApi = async (
  id: string
): Promise<AxiosResponse<IDealerQuota[]>> => {
  return await http.get(`/v1/admin/dealers/${id}/quota`);
};

export const updateDealerProfileApi = async (
  id: string,
  payload: UpdateDealerRequest
): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/admin/users/dealer/${id}/profile`, payload);
};

export const updateDealerQuotaApi = async (
  id: string,
  payload: UpdateQuotaRequest
): Promise<AxiosResponse<IDealerQuota[]>> => {
  return await http.put(`/v1/admin/users/dealer/${id}/quota`, payload);
};

export const transferDealerQuotaApi = async (
  id: string,
  payload: UpdateQuotaRequest
): Promise<AxiosResponse<IDealerQuota[]>> => {
  return await http.put(`/v1/admin/users/dealer/transfer/${id}/quota`, payload);
};

export const updateDealerInfoApi = async (
  id: string,
  payload: UpdateDealerInfoRequest
): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/admin/users/dealer/${id}/info`, payload);
};

export const updateDealerBookbankApi = async (
  id: string,
  payload: UpdateDealerBookbankRequest
): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/admin/users/dealer/${id}/bank`, payload);
};

export const updateAdminApi = async (
  id: string,
  payload: IUpdateAdmin
): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/admin/users/admin/${id}`, payload);
};

export const updatePasswordAdminApi = async (
  id: string,
  payload: { password: string }
): Promise<AxiosResponse<void>> => {
  return await http.patch(`/v1/admin/users/admin/password/${id}`, payload);
};

export const updatePasswordDealerApi = async (
  id: string,
  payload: { password: string }
): Promise<AxiosResponse<void>> => {
  return await http.patch(`/v1/admin/users/dealer/password/${id}`, payload);
};
